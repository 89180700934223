import "react-app-polyfill/ie11";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "raf/polyfill";
import "babel-preset-react-app";
import React from "react";
import ReactDOM from "react-dom";
import "./app.less";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
// import bridge from "@vkontakte/vk-bridge";
// import { CookiesProvider } from "react-cookie"; // TODO: сменить библиотеку для работы с cookie

import { Provider } from "react-redux";
import store from "./redux/store";
import { Spin } from "antd";

// bridge.send("VKWebAppInit");

if (null === localStorage.getItem("token")) {
  localStorage.setItem("token", Math.random().toString(36));
}

const LazyApp = React.lazy(() => import("./App"));

const SplashScreen = ({ tip }: { tip: string }) => (
  <div
    style={{
      position: "absolute",
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Spin size="large" tip={tip} />
  </div>
);

ReactDOM.render(
  <Provider store={store}>
    <React.Suspense fallback={<SplashScreen tip="loading..." />}>
      <LazyApp />
    </React.Suspense>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
